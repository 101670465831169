<template>
    <div id="fm-main-block">
        <file-manager :settings="settings"/>
    </div>
</template>

<script>
import '@/libs/file-manager'
export default {
    name: "FileManagerPage",
    data() {
        return {
            settings: {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                baseUrl: `${process.env.VUE_APP_BASE_API_URL}file-manager`,
                lang: this.$i18n.locale
            },
        }
    }
}
</script>

<style scoped>
@import url("https://use.fontawesome.com/releases/v5.7.0/css/all.css");

#fm-main-block{
    height: 100vh;
}
</style>